import { updateItemsList } from '@src/redux/slices/items.slice';
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApplicationResponse, StateTrackedData} from "@common/domain/common.model";
import {LocationStore, NewLocationModelRef} from "@views/locations/domain/locations.model";
import AppConstants from "@src/utils/app.constants";
import convertToForm from "@src/utils/payload-to-form-converter";
import api from "@src/common/services/api.service";


export const fetchLocationsList = createAsyncThunk<any[], void>(
    "locations/fetchLocations",
    async () => {

        // const response = await api.get<ApplicationResponse<any[]>>(`${AppConstants.api}/locations`)

        return [
            {
                name: "A-01",
                type: "RACK",
                parentLocation: "Location 1",
                capacity: "10 / 250",
                createdOn: "01 Jan 2023"
            },
            {
                name: "Location 1",
                type: "AREA",
                parentLocation: "--",
                capacity: "10 / 1000",
                createdOn: "01 Jan 2023"
            },
        ]
    })

    export const uploadLocations = createAsyncThunk<void, {
        payload: NewLocationModelRef
    }>("locations/upload",
        async (wrapper, thunkAPI) => {
            try {
                const url = `${AppConstants.api}/users/new`
                const {
                    payload
                } = wrapper
    
                const keys = Object.keys(payload) as Array<keyof NewLocationModelRef>
                const formData = convertToForm(payload, keys)
    
                const response = await api.post<ApplicationResponse<void>>(url, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
    
                return response.data.data
            } catch (err: any) {
                if (!err.response) {
                    return thunkAPI.rejectWithValue(err.response.data)
                }
    
                return thunkAPI.rejectWithValue({
                    error: {
                        message: "Network error",
                        code: "NETWORK_ERROR",
                        attributes: {}
                    },
                    status: "error",
                    timestamp: new Date().toISOString()
                })
            }
        })
const locationsSlice = createSlice({
    name: "locations",
    initialState: {minimal: {status: "loading", error: null, data: []}} as LocationStore,
    reducers: {
        updateLocationList:(state,action)=>{
            state.minimal.data.push(action.payload)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLocationsList.pending, (state, action) => {
                state.minimal = {
                    status: "loading",
                    data: [],
                    error: null
                }
            })
            .addCase(fetchLocationsList.fulfilled, (state, action) => {
                state.minimal = {
                    status: "idle",
                    data: action.payload,
                    error: null
                }
            })
            .addCase(fetchLocationsList.rejected, (state, action) => {
                state.minimal = {
                    status: "failed",
                    data: [],
                    error: action.error
                }
            })

    }
})
export const {updateLocationList} = locationsSlice.actions;
export default locationsSlice.reducer