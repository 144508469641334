import { MachineStore } from '@views/machines/domain/machine.model';
import AppConstants from "@utils/app.constants";
import {ApplicationResponse, StateTrackedData} from "@common/domain/common.model";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import api from "@common/services/api.service";



export const fetchMachinesList = createAsyncThunk<any[], any>(
    "machines/fetchMachines",
    async (payload) => {

        // const response = await api.get<ApplicationResponse<any[]>>(`${AppConstants.api}/machines`)

        // return response.data.data || []
      
        if(payload=="moulding"){
          return [
            {
              sNo: 1,
              machineName: "Horizontal Moulding Machine",
              machineNo: "KI-01",
              make: "TOYO",
              capacity: "130 TON",
              year: 2018
            },
            {
              sNo: 2,
              machineName: "Horizontal Moulding Machine",
              machineNo: "KI-02",
              make: "TOYO",
              capacity: "130 TON",
              year: 2018
            },
            {
              sNo: 3,
              machineName: "Horizontal Moulding Machine",
              machineNo: "KI-03",
              make: "TOYO",
              capacity: "100 TON",
              year: 2018
            },
            {
              sNo: 4,
              machineName: "Horizontal Moulding Machine",
              machineNo: "KI-04",
              make: "Huayuan Vietnam",
              capacity: "90 Ton",
              year: 2014
            },
            {
              sNo: 5,
              machineName: "Horizontal Moulding Machine",
              machineNo: "KI-05",
              make: "Toshiba",
              capacity: "100 Ton",
              year: 2015
            },
            {
              sNo: 6,
              machineName: "Horizontal Moulding Machine",
              machineNo: "KI-06",
              make: "Toshiba",
              capacity: "80 Ton",
              year: 2016
            },
            {
              sNo: 7,
              machineName: "Horizontal Moulding Machine",
              machineNo: "KI-07",
              make: "S & T",
              capacity: "130 TON",
              year: 2018
            },
            {
              sNo: 8,
              machineName: "Horizontal Moulding Machine",
              machineNo: "KI-09",
              make: "S & T",
              capacity: "130 TON",
              year: 2019
            },
            {
              sNo: 9,
              machineName: "Horizontal Moulding Machine",
              machineNo: "KI-12",
              make: "S & T",
              capacity: "130 TON",
              year: 2020
            },
            {
              sNo: 10,
              machineName: "Horizontal Moulding Machine",
              machineNo: "KI-15",
              make: "Bole",
              capacity: "110 TON",
              year: 2020
            },
            {
              sNo: 11,
              machineName: "Horizontal Moulding Machine",
              machineNo: "KI-22",
              make: "TOYO",
              capacity: "130 TON",
              year: 2022
            },
            {
              sNo: 12,
              machineName: "Horizontal Moulding Machine",
              machineNo: "KI-11",
              make: "S & T",
              capacity: "160 TON",
              year: 2020
            },
            {
              sNo: 13,
              machineName: "Horizontal Moulding Machine",
              machineNo: "KI-13",
              make: "S & T",
              capacity: "100 TON",
              year: 2020
            },
            {
              sNo: 14,
              machineName: "Horizontal Moulding Machine",
              machineNo: "KI-17",
              make: "Bole",
              capacity: "280 TON",
              year: 2020
            },
            {
              sNo: 15,
              machineName: "Horizontal Moulding Machine",
              machineNo: "KI-21",
              make: "TOYO",
              capacity: "130 TON",
              year: 2022
            },
            {
              sNo: 16,
              machineName: "Horizontal Moulding Machine",
              machineNo: "KI-23",
              make: "TOYO",
              capacity: "100 TON",
              year: 2022
            },
            {
              sNo: 17,
              machineName: "Horizontal Moulding Machine",
              machineNo: "KI-08",
              make: "Bole",
              capacity: "280 TON",
              year: 2018
            },
            {
              sNo: 18,
              machineName: "Horizontal Moulding Machine",
              machineNo: "KI-10",
              make: "Bole",
              capacity: "280 TON",
              year: 2019
            },
            {
              sNo: 19,
              machineName: "Vertical Moulding Machine",
              machineNo: "KI-14",
              make: "STM (D&M)",
              capacity: "85 TON",
              year: 2020
            },
            {
              sNo: 20,
              machineName: "Vertical Moulding Machine",
              machineNo: "KI-16",
              make: "STM (D&M)",
              capacity: "85 TON",
              year: 2020
            },
            {
              sNo: 21,
              machineName: "Vertical Moulding Machine",
              machineNo: "KI-19",
              make: "STM (D&M)",
              capacity: "45 TON",
              year: 2021
            },
            {
              sNo: 22,
              machineName: "Vertical Moulding Machine",
              machineNo: "KI-20",
              make: "STM (D&M)",
              capacity: "45 TON",
              year: 2016
            },
            {
              sNo: 23,
              machineName: "Horizontal Moulding Machine",
              machineNo: "KI-24",
              make: "TOYO",
              capacity: "100 TON",
              year: 2022
            },
            {
              sNo: 24,
              machineName: "Vertical Moulding Machine",
              machineNo: "KI-25",
              make: "STM (D&M)",
              capacity: "85 TON",
              year: 2022
            },
            {
              sNo: 25,
              machineName: "Vertical Moulding Machine",
              machineNo: "KI-18",
              make: "STM (D&M)",
              capacity: "45 TON",
              year: 2022
            },
            {
              sNo: 26,
              machineName: "Vertical Moulding Machine",
              machineNo: "KI-26",
              make: "STM (D&M)",
              capacity: "85 TON",
              year: 2022
            }
          ];
        }
        else if(payload=="skh-sila"){
        return [
          {
            sNo: 1,
            machineName: "Plastic Sleeve & Metal Bush Assy. M/c",
            machineNo: "Line-01",
            make: "Sam Control",
            location: "SKH-SILA Assy.",
            year: 2022
          },
          {
            sNo: 2,
            machineName: "Quick Fit Complete M/c",
            machineNo: "Line-02",
            make: "Sam Control",
            location: "SKH-SILA Assy.",
            year: 2022
          },
          {
            sNo: 3,
            machineName: "YG8 Terminal End Assy",
            machineNo: "Line-03",
            make: "Sam Control",
            location: "SKH-SILA Assy.",
            year: 2022
          },
          {
            sNo: 4,
            machineName: "QA Adjuster Machine",
            machineNo: "Line-04",
            make: "Sam Control",
            location: "SKH-SILA Assy.",
            year: 2022
          },
          {
            sNo: 5,
            machineName: "Quick Fit Complete Tappo M/c",
            machineNo: "Line-05",
            make: "Sam Control",
            location: "SKH-SILA Assy.",
            year: 2022
          },
          {
            sNo: 6,
            machineName: "Cable End Assembly M/c",
            machineNo: "Line-06",
            make: "Prince automation",
            location: "SKH-SILA Assy.",
            year: 2022
          },
          {
            sNo: 7,
            machineName: "Quick Fit Complete M/c",
            machineNo: "Line-07",
            make: "Prince automation",
            location: "SKH-SILA Assy.",
            year: 2023
          },
          {
            sNo: 8,
            machineName: "Cable End Assembly M/c (SKH-Sila)",
            machineNo: "Line-08",
            make: "Prince automation",
            location: "SKH-SILA Assy.",
            year: 2023
          },
          {
            sNo: 9,
            machineName: "Plastic Sleeve & Metal Bush Assy. M/c",
            machineNo: "Line-09",
            make: "Sam Control",
            location: "SKH-SILA Assy.",
            year: 2023
          }
        ];
        }
        else{
        return [
          {
            sNo: 1,
            machineName: "Damper Pipe Assy. M/c",
            machineNo: "A-01",
            make: "Prince automation",
            location: "Hi-Lex Assy.",
            year: 2018
          },
          {
            sNo: 2,
            machineName: "Steel Guide Pipe Assy. M/c",
            machineNo: "A-02",
            make: "Prince automation",
            location: "Hi-Lex Assy.",
            year: 2018
          },
          {
            sNo: 3,
            machineName: "Damper Pipe Assy. M/c",
            machineNo: "D-01",
            make: "Prince automation",
            location: "Hi-Lex Assy.",
            year: 2019
          },
          {
            sNo: 4,
            machineName: "Casing Cap Gate Cutting M/C",
            machineNo: "D-02",
            make: "Prince automation",
            location: "Hi-Lex Assy.",
            year: 2019
          },
          {
            sNo: 5,
            machineName: "Damper Pipe Assy. M/c",
            machineNo: "D-06",
            make: "Prince automation",
            location: "Hi-Lex Assy.",
            year: 2022
          },
          {
            sNo: 6,
            machineName: "Damper Pipe Assy. M/c",
            machineNo: "D-07",
            make: "Prince automation",
            location: "Hi-Lex Assy.",
            year: 2022
          },
          {
            sNo: 7,
            machineName: "Casing Cap Assy. M/c",
            machineNo: "Line-01 / A-03",
            make: "Prince automation",
            location: "Hi-Lex Assy.",
            year: 2018
          },
          {
            sNo: 8,
            machineName: "Casing Cap Testing M/c",
            machineNo: "Line-01 / A-04",
            make: "Prince automation",
            location: "Hi-Lex Assy.",
            year: 2018
          },
          {
            sNo: 9,
            machineName: "Casing cap Batch Code",
            machineNo: "Line-01 / A-05",
            make: "Prince automation",
            location: "Hi-Lex Assy.",
            year: 2018
          },
          {
            sNo: 10,
            machineName: "Casing Cap Assy. M/c",
            machineNo: "Line-02 / B-01",
            make: "Prince automation",
            location: "Hi-Lex Assy.",
            year: 2019
          },
          {
            sNo: 11,
            machineName: "Casing Cap Testing M/c",
            machineNo: "Liine-02 / B-02",
            make: "Prince automation",
            location: "Hi-Lex Assy.",
            year: 2019
          },
          {
            sNo: 12,
            machineName: "Casing cap Batch Code",
            machineNo: "Line-02 / B-03",
            make: "Prince automation",
            location: "Hi-Lex Assy.",
            year: 2019
          },
          {
            sNo: 13,
            machineName: "Casing Cap Assy. M/c",
            machineNo: "Line-03 / C-01",
            make: "Prince automation",
            location: "Hi-Lex Assy.",
            year: 2019
          },
          {
            sNo: 14,
            machineName: "Casing Cap Testing M/c",
            machineNo: "Line-03 / C-02",
            make: "Prince automation",
            location: "Hi-Lex Assy.",
            year: 2019
          },
          {
            sNo: 15,
            machineName: "Casing cap Batch Code",
            machineNo: "Line-03 / C-03",
            make: "Prince automation",
            location: "Hi-Lex Assy.",
            year: 2019
          },
          {
            sNo: 16,
            machineName: "Casing Cap Assy. M/c",
            machineNo: "Line-04 / E-01",
            make: "Prince automation",
            location: "Hi-Lex Assy.",
            year: 2019
          },
          {
            sNo: 17,
            machineName: "Casing Cap Testing M/c",
            machineNo: "Line-04 / E-02",
            make: "Prince automation",
            location: "Hi-Lex Assy.",
            year: 2019
          },
          {
            sNo: 18,
            machineName: "Casing cap Batch Code",
            machineNo: "Line-04 / E-03",
            make: "Prince automation",
            location: "Hi-Lex Assy.",
            year: 2019
          },
          {
            sNo: 19,
            machineName: "Lever FLO/Hood Assy. M/c",
            machineNo: "Line-05 / F-01",
            make: "Alliance",
            location: "Hi-Lex Assy.",
            year: 2020
          },
          {
            sNo: 20,
            machineName: "Eye End Assembly M/c",
            machineNo: "Line-06",
            make: "Prince automation",
            location: "Hi-Lex Assy.",
            year: 2021
          },
          {
            sNo: 21,
            machineName: "Adjuster Assembly M/c",
            machineNo: "Line-07",
            make: "Prince automation",
            location: "Hi-Lex Assy.",
            year: 2021
          },
          {
            sNo: 22,
            machineName: "Eye End Assembly M/c",
            machineNo: "Line-08",
            make: "Prince automation",
            location: "Hi-Lex Assy.",
            year: 2022
          }
        ];
        }
    })

const machinesSlice = createSlice({
    name: "machines",
    initialState: {minimal: {status: "loading", error: null, data: []}} as MachineStore,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMachinesList.pending, (state, action) => {
                state.minimal = {
                    status: "loading",
                    data: [],
                    error: null
                }
            })
            .addCase(fetchMachinesList.fulfilled, (state, action) => {
                state.minimal = {
                    status: "idle",
                    data: action.payload,
                    error: null
                }
            })
            .addCase(fetchMachinesList.rejected, (state, action) => {
                state.minimal = {
                    status: "idle",
                    data: [],
                    error: action.error
                }
            })

    }
})

export default machinesSlice.reducer