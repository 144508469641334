import {toast} from "react-toastify"
import {PiCheckBold} from "react-icons/pi"
import React from "react"

export function showSuccessToast(message?: string) {
    toast(
        <div className="flex justify-start items-center relative !overflow-visible">
            <div className="h-[5rem] w-[5rem] z-[0] flex justify-center items-center bg-green-700 rounded-full absolute top-[-2rem] left-[-2rem]">
                <PiCheckBold className="h-[4rem] w-[4rem] text-white/50"/>
            </div>
            <span className="text-white/75 font-semibold z-[1] ml-10">{message ?? "Request completed successfully!"}</span>
        </div>, {
            className: "!bg-green-600 !rounded-lg !drop-shadow-sm",
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
        })
}