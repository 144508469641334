import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import api from "@common/services/api.service";
import AppConstants from "@utils/app.constants";
import {ApplicationResponse, StateTrackedData} from "@common/domain/common.model";
import {ItemStore, NewItemModelRef} from "@views/items/domain/items.model";
import convertToForm from "@src/utils/payload-to-form-converter";

const mockedList = [
    {
        id: "1",
        name: "PA16 55",
        type: "Best Of Product",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent iaculis sagittis nisl a tempor. Nam scelerisque neque urna, in tristique elit tincidunt ac. Donec rhoncus, sapien interdum elementum ornare, libero sapien rhoncus velit, sit amet blandit urna enim a nunc.",
        orders: [
            {
                id: "o1",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o2",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o3",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o4",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o5",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o6",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o7",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o8",
                invoiceNo: "YG8 Terminal End Assy"
            }
        ]
    },
    {
        id: "2",
        name: "MB15 99",
        type: "RAW MATERIAL",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent iaculis sagittis nisl a tempor. Nam scelerisque neque urna, in tristique elit tincidunt ac. Donec rhoncus, sapien interdum elementum ornare, libero sapien rhoncus velit, sit amet blandit urna enim a nunc.",
        orders: [
            {
                id: "o1",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o2",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o3",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o4",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o5",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o6",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o7",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o8",
                invoiceNo: "YG8 Terminal End Assy"
            }
        ]
    },
    {
        id: "3",
        name: "Nylon 66",
        type: "RAW MATERIALS",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent iaculis sagittis nisl a tempor. Nam scelerisque neque urna, in tristique elit tincidunt ac. Donec rhoncus, sapien interdum elementum ornare, libero sapien rhoncus velit, sit amet blandit urna enim a nunc.",
        orders: [
            {
                id: "o1",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o2",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o3",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o4",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o5",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o6",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o7",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o8",
                invoiceNo: "YG8 Terminal End Assy"
            }
        ]
    },
    {
        id: "4",
        name: "Nylon 81",
        type: "BEST OF PRODUCT",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent iaculis sagittis nisl a tempor. Nam scelerisque neque urna, in tristique elit tincidunt ac. Donec rhoncus, sapien interdum elementum ornare, libero sapien rhoncus velit, sit amet blandit urna enim a nunc.",
        orders: [
            {
                id: "o1",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o2",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o3",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o4",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o5",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o6",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o7",
                invoiceNo: "YG8 Terminal End Assy"
            },
            {
                id: "o8",
                invoiceNo: "YG8 Terminal End Assy"
            }
        ]
    }
];
export const fetchItemsList = createAsyncThunk<any[], void>(
    "items/fetchItems",
    async () => {

        // const response = await api.get<ApplicationResponse<any[]>>(`${AppConstants.api}/items`)
        //
        // return response.data.data || []

        return mockedList;
    })

    export const uploadItems = createAsyncThunk<void, {
        payload: NewItemModelRef
    }>("items/upload",
        async (wrapper, thunkAPI) => {
            try {
                const url = `${AppConstants.api}/users/new`
                const {
                    payload
                } = wrapper
    
                const keys = Object.keys(payload) as Array<keyof NewItemModelRef>
                const formData = convertToForm(payload, keys)
    
                const response = await api.post<ApplicationResponse<void>>(url, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
    
                return response.data.data
            } catch (err: any) {
                if (!err.response) {
                    return thunkAPI.rejectWithValue(err.response.data)
                }
    
                return thunkAPI.rejectWithValue({
                    error: {
                        message: "Network error",
                        code: "NETWORK_ERROR",
                        attributes: {}
                    },
                    status: "error",
                    timestamp: new Date().toISOString()
                })
            }
        })
const itemsSlice = createSlice({
    name: "items",
    initialState: {minimal: {status: "loading", error: null, data: []}} as ItemStore,
    reducers: {
        updateItemsList: (state,action)=>{
            state.minimal.data.push(action.payload);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchItemsList.pending, (state, action) => {
                state.minimal = {
                    status: "loading",
                    data: [],
                    error: null
                }
            })
            .addCase(fetchItemsList.fulfilled, (state, action) => {
                state.minimal = {
                    status: "idle",
                    data: action.payload,
                    error: null
                }
            })
            .addCase(fetchItemsList.rejected, (state, action) => {
                state.minimal = {
                    status: "idle",
                    data: [],
                    error: action.error
                }
            })

    }
})
export const {updateItemsList} = itemsSlice.actions;
export default itemsSlice.reducer