import {combineReducers} from "@reduxjs/toolkit"

import dialogReducer from "@slices/dialog.slice"
import authReducer from "@slices/auth.slice"
import configReducer from "@slices/config.slice"
import itemsReducer from "@slices/items.slice"
import machinesReducer from "@slices/machines.slice"
import locationsReducer from "@slices/locations.slice"
import partnersReducer from "@slices/partners.slice"
import ordersReducer from "@slices/orders.slice"
import inventoryReducer from "@slices/inventory.slice"

const rootReducer = combineReducers({
    dialogs: dialogReducer,
    auth: authReducer,
    config: configReducer,
    items: itemsReducer,
    machines: machinesReducer,
    locations: locationsReducer,
    partners: partnersReducer,
    orders: ordersReducer,
    inventory: inventoryReducer
})

export default rootReducer
