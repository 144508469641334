import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {OrderStore} from "@views/orders/domain/orders.model";

export const fetchOrdersList = createAsyncThunk<any[], void>(
    "orders/fetchOrders",
    async () => {

        // const response = await api.get<ApplicationResponse<any[]>>(`${AppConstants.api}/locations`)

        return [
            {
                invoiceNo: "INV-1",
                type: "Picking",
                source: "Location 1",
                destination: "Location 2",
                createdOn: "01 Jan 2023"
            },
            {
                invoiceNo: "INV-2",
                type: "Putting",
                source: "Location 1",
                destination: "Location 2",
                createdOn: "01 Jan 2023"
            },
        ]
    })


const ordersSlice = createSlice({
    name: "orders",
    initialState: {minimal: {status: "loading", error: null, data: []}} as OrderStore,
    reducers: {
        updateOrdersList: (state,action)=>{
            state.minimal.data.push(action.payload)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrdersList.pending, (state, action) => {
                state.minimal = {
                    status: "loading",
                    data: [],
                    error: null
                }
            })
            .addCase(fetchOrdersList.fulfilled, (state, action) => {
                state.minimal = {
                    status: "idle",
                    data: action.payload,
                    error: null
                }
            })
            .addCase(fetchOrdersList.rejected, (state, action) => {
                state.minimal = {
                    status: "failed",
                    data: [],
                    error: action.error
                }
            })

    }
})

export const {updateOrdersList} = ordersSlice.actions;
export default ordersSlice.reducer